











































































import { Vector2 } from 'three'
import { Getter } from 'vuex-class'
import { WidgetActions, MediaQ } from '@/constants'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { HotspotState, RetailerState } from '@/store/types'
import Swiper, { Mousewheel, Scrollbar } from 'swiper'
import VideoCarousel from '@/components/molecules/widgets/VideoCarousel.vue'
import ImageGallery from '@/components/molecules/widgets/ImageGallery.vue'
import ImagePattern from '@/components/molecules/widgets/ImagePattern.vue'
import ImagePoster from '@/components/molecules/widgets/ImagePoster.vue'
import AudioPlayer from '@/components/molecules/widgets/AudioPlayer.vue'
import MobileLink from '@/components/molecules/widgets/MobileLink.vue'
import Button from '@/components/atoms/Button.vue'
import gsap from 'gsap'
import wx from "weixin-js-sdk"

import Burieds from "@/utils/buried";
import Request from "@/utils/request";


Swiper.use([Mousewheel, Scrollbar])

@Component({
  components: {
    Button,
    MobileLink,
    AudioPlayer,
    ImagePoster,
    ImagePattern,
    ImageGallery,
    VideoCarousel,
  }
})
export default class Overlay extends Vue {
  @Getter('retailer')
  retailer!: RetailerState

  @Getter('shoplink')
  shoplink!: string

  @Getter('explink')
  explink!: string

  @Getter('arlink')
  arlink!: string

  @Prop()
  hotspot!: HotspotState

  @Prop()
  viewport!: Vector2

  @Prop()
  visible!: boolean

  @Prop()
  muted!: boolean

  @Prop()
  scene!: string

  scroller!: Swiper

  $refs!: {
    panel: HTMLElement
    scroller: HTMLElement
    scrollbar: HTMLElement
  }

  get widget () {
    console.log("插件类型", this.hotspot);
    return this.hotspot.type
  }

  get action () {
    return this.hotspot.action
  }

  get needsShopCta () {
    return WidgetActions.SHOP === this.action && this.retailer.shop !== undefined
  }

  get needsArCta () {
    return WidgetActions.AR === this.action && this.$device.mobile
  }

  get needsExpCta () {
    return WidgetActions.EXP === this.action && this.$device.mobile
  }

  get actions () {
    return !this.needsShopCta && !this.needsExpCta && !this.needsArCta ? 'no-actions' : ''
  }


  @Watch('viewport', { deep: true, immediate: true })
  async resize () {
    await this.$nextTick()

    const { panel, scroller } = this.$refs
    const { innerWidth, innerHeight } = window

    const offset = innerWidth >= MediaQ.MD ? 60 : innerWidth >= MediaQ.XS ? 60 : 40

    gsap.set([panel, scroller], { maxHeight: innerHeight - offset })

    this.scroller.update()
  }

  enter ($el: HTMLElement, done: () => void) {
    const $panel = $el.querySelector('.panel')

    gsap.timeline({ delay: .4, onComplete: done })
        .fromTo($el, { opacity: 0 }, { opacity: 1, duration: .8, ease: 'power2.inOut' }, '<')
        .fromTo($panel, { y: 0, opacity: 0 }, { y: 0, opacity: 1, duration: .8, ease: 'power2.out' }, '<+.1')
  }

  leave ($el: HTMLElement, done: () => void) {
    gsap.timeline({ onComplete: done })
        .to($el, { opacity: 0, duration: .6, ease: 'power2.out' })
  }

  mounted () {
    const { scroller, scrollbar } = this.$refs
    const { mobile } = this.$device

    this.scroller = new Swiper(scroller, {
      direction: 'vertical',
      slidesPerView: 'auto',
      allowTouchMove: mobile,
      mousewheel: !mobile,
      freeMode: true,
      scrollbar: {
        el: scrollbar,
      },
    })
  }
  /**
   * 跳转到小程序
   * @param  {[type]} retailer [description]
   * @return {[type]}          [description]
   */
  navigateToMiniPrograme(hotspot: any){
    console.log("去小程序1",hotspot);
    let { url } = hotspot;
    if(!url) return;
    this.setTrackEvent(hotspot)
    wx.miniProgram.navigateTo({url: `/florafantasyworld/main/pages/jump/jump?pathKey=${url}`})
  }

  setTrackEvent(hotspot: any){
      const url = hotspot.url || ""
      const eventObject = [
        {name:'garden_flower',type: Burieds.pearPop},
        {name:'garden_bottle',type: Burieds.floraBotterPop},
        {name:'garden_jasmine',type: Burieds.jasminePop},
        {name:'garden_gardenia',type: Burieds.gardeniaPop},
      ]
      const currentItem = eventObject.find(item => url ==item.name)
      if(currentItem){
        const type = currentItem.type
        Request.setBuriedEvent(type).then( (res: any) => {
          console.log("res", res);
        })
      }
  }
  //第三方插件
  navigateToAR(){
    console.log("AR增强");
    Request.setBuriedEvent(Burieds.hitAR).then( (res: any) => {
      console.log("res", res);
    })
    wx.miniProgram.navigateTo({ url: 'plugin://gucci-flora/home' })
  }

  //三连拍
  explinkMiniPrograme(){
    Request.setBuriedEvent(Burieds.popSart).then( (res: any) => {
      console.log("res", res);
    })
    wx.miniProgram.navigateTo({url: '/florafantasyworld/main/pages/purikura/purikura'})
  }

  destroyed () {
    this.scroller.destroy(true, false)
  }
}
