import axios from "axios";
import API from './api'


// API请求地址
const API_PATH = process.env.VUE_APP_API_PATH;

const  query = window.location.href;
const  queryList = query.split("#");
let open_id =   queryList[1] || ""
if(open_id && open_id.includes("%")){
  open_id = open_id.split("%")[0]
}

console.log("open_id", open_id);

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 请求接口实现
export default {
  setTrackEvent: () => axios.get(`${API_PATH}${API.login}`),
  setBuriedEvent: type => axios.post(API_PATH  + `${API.buriedApi}`, {
    activityId: 0,
    open_id: open_id || "",
    channel: "WeChat",
    type:type || "webview"
}),
};
