import { Vector2 } from 'three'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class ResizeProvider extends Vue {
  viewport = new Vector2() 

  resize () {
    this.viewport.x = window.innerWidth
    this.viewport.y = window.innerHeight
  }

  mounted () {
    window.addEventListener('resize', this.resize)
    this.resize()
  }
  
  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  render () {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        viewport: this.viewport
      })
    )
  }
}