





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop()
  mode!: string

  @Prop()
  icon!: string

  @Prop()
  href!: string

  @Prop()
  outline!: boolean

  @Prop({ default: '_blank' })
  target!: string
}
