


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PlayIcon extends Vue {
  @Prop()
  paused!: boolean
}
