//import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { LoadingManager, TextureLoader, sRGBEncoding, RepeatWrapping, EquirectangularReflectionMapping, HalfFloatType, AudioLoader, LinearFilter } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Scenes } from '@/constants'
import * as cache from '@/services/cache'
import store from '@/store'

const { BASE_URL: base } = process.env

const manager = new LoadingManager()

//const dracoLoader = new DRACOLoader(manager)
//dracoLoader.setDecoderPath(`${base}draco`)

const gltfLoader = new GLTFLoader(manager)
//gltfLoader.setDRACOLoader(dracoLoader)

const textureLoader = new TextureLoader(manager)

const audioLoader = new AudioLoader(manager)

const fetchSharedAssets = () => {
  const sharedCache = `shared-cache`

  cache.add(sharedCache, {});

  // textures
  [
    { key: 'map-env', source: `${base}img/textures/maps/env-map.jpg` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      texture.mapping = EquirectangularReflectionMapping
      texture.encoding = sRGBEncoding
      cache.get(sharedCache)[key] = texture
    })
  });

  [
    { key: 'map-leaf', source: `${base}img/textures/maps/leaf-map.png` },
    { key: 'map-flower-1', source: `${base}img/textures/flowers/01.png` },
    { key: 'map-flower-2', source: `${base}img/textures/flowers/02.png` },
    { key: 'map-flower-3', source: `${base}img/textures/flowers/03.png` },
    { key: 'map-flower-4', source: `${base}img/textures/flowers/04.png` },
    { key: 'map-flower-5', source: `${base}img/textures/flowers/05.png` },
    { key: 'map-flower-6', source: `${base}img/textures/flowers/06.png` },
    { key: 'map-flower-7', source: `${base}img/textures/flowers/07.png` },
    { key: 'map-flower-8', source: `${base}img/textures/flowers/08.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      texture.encoding = sRGBEncoding
      cache.get(sharedCache)[key] = texture
    })
  });

  [
    { key: 'map-cloud-1', source: `${base}img/textures/maps/Texture_nuovola_2_alpha_Texture_nuovola_2.png` },
    { key: 'map-cloud-2', source: `${base}img/textures/maps/Texture_nuovola_1_alpha_Texture_nuovola_1.png` },
    { key: 'sprite-cat-tail-cat-porch', source: `${base}img/textures/sprites/cat-tail-cat-porch-sprite.png` },
    { key: 'sprite-cats', source: `${base}img/textures/sprites/cats-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sharedCache)[key] = texture
    })
  });
  
  // sounds
  [
    { key: 'sound-garden-environment', source: `${base}audio/Ambiente_1.mp3` },
    { key: 'sound-porch-environment', source: `${base}audio/Ambiente_2.mp3` },
    { key: 'sound-beach-environment', source: `${base}audio/Ambiente_3.mp3` },
    { key: 'sound-transition', source: `${base}audio/Transizione.mp3` },
    { key: 'sound-hotspot', source: `${base}audio/Tap.mp3` },
  ].map(({ key, source }: any) => {
    audioLoader.load(source, buffer => {
      cache.get(sharedCache)[key] = buffer
    })
  });
}

const fetchGardenSceneAssets = () => {
  const sceneCache = `${Scenes.GARDEN}-cache`

  cache.add(sceneCache, {});
  
  // environment
  [
    { key: 'gltf-environment', source: `${base}gltf/garden/enviroment_casa_V19.gltf` },
  ].map(({ key, source }: any) => {
    gltfLoader.load(source, ({ scene }) => {
      cache.get(sceneCache)[key] = scene
    })
  });

  // sprites
  [
    { key: 'sprite-pear-blossom-accord', source: `${base}img/textures/sprites/pear-blossom-accord-sprite.png` },
    { key: 'sprite-jasmine-white-gardenia', source: `${base}img/textures/sprites/jasmine-white-gardenia-sprite.png` },
    { key: 'sprite-cat-ear', source: `${base}img/textures/sprites/cat-ear-sprite.png` },
    { key: 'sprite-cat-dog', source: `${base}img/textures/sprites/cat-dog-sprite.png` },
    { key: 'sprite-dog', source: `${base}img/textures/sprites/dog-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter 
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  });
}

const fetchPorchSceneAssets = () => {
  const sceneCache = `${Scenes.PORCH}-cache`

  cache.add(sceneCache, {});

  // environment
  [
    { key: 'gltf-environment', source: `${base}gltf/porch/enviroment_portico_V14.gltf` },
  ].map(({ key, source }: any) => {
    gltfLoader.load(source, ({ scene }) => {
      cache.get(sceneCache)[key] = scene
    })
  });

  // sprites
  [
    { key: 'sprite-stairs-cat', source: `${base}img/textures/sprites/stairs-cat-sprite.png` },
    { key: 'sprite-red-cat', source: `${base}img/textures/sprites/red-cat-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  });
}

const fetchBeachSceneAssets = () => {
  const sceneCache = `${Scenes.BEACH}-cache`

  cache.add(sceneCache, {});

  // environment
  [
    { key: 'gltf-environment', source: `${base}gltf/beach/enviroment_spiaggia_V15.gltf` },
  ].map(({ key, source }: any) => {
    gltfLoader.load(source, ({ scene }) => {
      cache.get(sceneCache)[key] = scene
    })
  });

  // sprites
  [
    { key: 'white-gardenia-sprite', source: `${base}img/textures/sprites/white-gardenia-sprite.png` },
    { key: 'dog-crab-sprite', source: `${base}img/textures/sprites/dog-crab-sprite.png` },
    { key: 'shell-sprite', source: `${base}img/textures/sprites/shell-sprite.png` },
    { key: 'crab-sprite', source: `${base}img/textures/sprites/crab-sprite.png` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      texture.wrapS = texture.wrapT = RepeatWrapping
      texture.minFilter = LinearFilter 
      texture.encoding = sRGBEncoding
      texture.type = HalfFloatType
      texture.flipY = false
      cache.get(sceneCache)[key] = texture
    })
  });

  // water
  [
    { key: 'normal0', source: `${base}img/textures/water/Water_1_M_Normal.jpg` },
    { key: 'normal1', source: `${base}img/textures/water/Water_2_M_Normal.jpg` },
    { key: 'flow', source: `${base}img/textures/water/Water_1_M_Flow.jpg` },
  ].map(({ key, source }: any) => {
    textureLoader.load(source, texture => {
      cache.get(sceneCache)[key] = texture
    })
  })
}

export const fetchSceneAssets = (scene?: string) => {
  switch (scene) {
    case Scenes.GARDEN : 
      fetchGardenSceneAssets() 
      break
    case Scenes.PORCH : 
      fetchPorchSceneAssets() 
      break
    case Scenes.BEACH : 
      fetchBeachSceneAssets() 
      break
  }

  if (!cache.get('sharedCache')) {
    fetchSharedAssets()
  }

  return new Promise<void>(resolve => {
    manager.onProgress = (_, loaded, total) => store.dispatch('progress', loaded / total)
    manager.onLoad = () => resolve()
  })
}