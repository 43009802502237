import { Vector2 } from 'three'
import { HotspotState } from '@/store/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class MediaWidget extends Vue {
  @Prop()
  model!: HotspotState

  @Prop()
  viewport!: Vector2

  @Prop()
  visible!: boolean

  @Prop()
  muted!: boolean

  @Prop()
  scene!: string

  get media (): any {
    return this.model.media
  }

  get base () {
    return process.env.BASE_URL
  }

  // playerOptions = {
  //   autoplay: true,
  //   sources: [{
  //           type: "video/mp4",
  //           src: "https://guccifloraplatform.aqdemo.it/hotspots/img/garden/pear-blossom-accord.mp4"
  //       }],
  // }

  @Watch('viewport', { deep: true, immediate: true })
  async resize () {
    return
  }

  getRatio ({ width, height }: any) {
    return { padding: `${height / width * 100}% 0 0` }
  }
}
