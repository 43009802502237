




















































































































































































































































































































import { HotspotState, RetailerState } from '@/store/types'
import { Action, Getter } from 'vuex-class'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { StateProvider, ResizeProvider, PointerProvider, VisibilityProvider, MixerProvider, TrackingProvider } from '@/providers'
import Navigator from '@/components/organsims/Navigator.vue'
import Hotspots from '@/components/organsims/Hotspots.vue'
import Tutorial from '@/components/organsims/Tutorial.vue'
import Welcome from '@/components/organsims/Welcome.vue'
import Overlay from '@/components/organsims/Overlay.vue'
import Landing from '@/components/organsims/Landing.vue'
import Header from '@/components/molecules/Header.vue'
import Footer from '@/components/molecules/Footer.vue'
import Loader from '@/components/organsims/Loader.vue'
import Dolly from '@/components/organsims/Dolly.vue'
import BaseScene from '@/webgl/scenes/base/Base'

import {GTMEventCategory ,eventPush} from "../providers/modules/Tracking";


import Request from "../utils/request.js";

@Component({
  components: {
    ThreeProvider: () => import(/* webpackChunkName: "webgl" */ '@/webgl/providers/Three'),
    GardenScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/garden/Garden.vue'),
    PorchScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/porch/Porch.vue'),
    BeachScene: () => import(/* webpackChunkName: "webgl" */ '@/webgl/scenes/beach/Beach.vue'),
    RenderPass: () => import(/* webpackChunkName: "webgl" */ '@/webgl/postprocessing/RenderPass'),
    BloomPass: () => import(/* webpackChunkName: "webgl" */ '@/webgl/postprocessing/BloomPass'),
    ChunkPass: () => import(/* webpackChunkName: "webgl" */ '@/webgl/postprocessing/ChunkPass'),
    RGBChunk: () => import(/* webpackChunkName: "webgl" */ '@/webgl/postprocessing/ChunkPass/chunks/RGB'),
    Starlight: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Starlight'),
    Sprinkles: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Sprinkles'),
    Flowers: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Flowers'),
    Lights: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Lights'),
    Glow: () => import(/* webpackChunkName: "webgl" */ '@/webgl/components/Glow'),
    Canvas: () => import(/* webpackChunkName: "webgl" */ '@/webgl/Canvas.vue'),
    Effects: () => import(/* webpackChunkName: "webgl" */ '@/webgl/Effects.vue'),
    VisibilityProvider,
    TrackingProvider,
    PointerProvider,
    ResizeProvider,
    StateProvider,
    MixerProvider,
    Navigator,
    Hotspots,
    Tutorial,
    Welcome,
    Overlay,
    Landing,
    Loader,
    Header,
    Footer,
    Dolly,
  }
})
export default class Root extends Vue {
  @Getter('hotspots')
  hotspots!: HotspotState[]

  @Getter('hotspot')
  currentHotspot!: HotspotState

  @Getter('retailer')
  currentRetailer!: RetailerState

  @Getter('scene')
  currentScene!: string

  @Getter('complete')
  complete!: boolean

  @Getter('started')
  started!: boolean

  @Getter('tutorial')
  tutorial!: boolean

  @Getter('welcome')
  welcome!: boolean

  @Getter('locale')
  locale!: boolean

  @Getter('devcam')
  devcam!: boolean

  @Getter('debug')
  debug!: boolean

  @Action('fetch')
  fetch!: () => void

  @Action('finish')
  finish!: () => void

  @Action('start')
  starExperience!: () => void

  @Action('gotoNextScene')
  gotoNextScene!: () => void

  @Action('setScene')
  setScene!: (scene: string) => void

  @Action('setWelcomeState')
  setWelcomeState!: () => void

  @Action('setTutorialState')
  setTutorialState!: () => void

  @Action('setCurrentHotspot')
  setCurrentHotspot!: (hotspot: HotspotState | null) => void

  sceneLoading = false
  sceneLanding = true

  $refs!: {
    scene: BaseScene
    loader: Loader
  }

  @Watch('currentScene', { immediate: true })
  onSceneChange () {
    this.sceneLoading = true
  }

  @Watch('started')
  async onStarted () {
    await this.$timer.defer(.8)
    this.$refs.scene.reveal()
  }

  @Watch('tutorial')
  async onConfirm () {
    await this.$nextTick()
    this.sceneLanding = false
  }

  get sceneComponent () {
    const sceneComponent = `${this.$fn.capitalize(this.currentScene)}Scene`
    console.log("切换", sceneComponent);
    return this.currentScene.length > 0 ? sceneComponent : null
  }

  get active () {
    return !this.sceneLoading && this.started && this.welcome && this.tutorial
  }

  get needsLoader () {
    return this.complete && this.sceneLoading
  }

  get needsLanding () {
    return this.locale && !this.started
  }

  get needsWelcome () {
    return this.started && !this.welcome
  }

  get needsTutorial () {
    return this.started && this.welcome && !this.tutorial
  }

  get needsOverlay () {
    return this.currentHotspot !== null
  }


  get needsEffects () {
    return this.$gpu.getPP()
  }

  async sceneLeave (_: any, done: () => void) {
    await this.$refs.scene.unmount(done)
  }

  async sceneEnter (_: any, done: () => void) {
    await this.$refs.scene.mount(done, this.started)
    this.sceneLoading = false
  }

  // setTrackEvent() {
  //   Request.setTrackEvent().then( (res: any) => {
  //     console.log("res", res);
  //   })
  // }

  async mounted () {
    await this.$timer.defer(2)
    await this.fetch()
    await this.$timer.defer(.6)
    await this.finish()
    // this.setTrackEvent()
    window.onhashchange = () => {
        const url = window.location.href
        const vars = url.split("%");
        const varscene  = vars[1] || ""
        if(varscene){
          eventPush({eventCategory: GTMEventCategory.DOORS, eventAction: this.$fn.capitalize(varscene)})
          this.setScene(varscene)
        }
    }
  }
}
