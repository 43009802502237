

































import { gsap } from 'gsap'
import { HotspotState } from '@/store/types'
import { Events, ENTER_DURATION } from '@/constants'
import { PerspectiveCamera, Vector2 } from 'three'
import { Component, Vue, Prop, Inject } from 'vue-property-decorator'
import wx from "weixin-js-sdk"

import Burieds from "@/utils/buried";
import Request from "@/utils/request";

@Component
export default class Hotspots extends Vue {
  @Prop()
  collection!: HotspotState[]

  @Prop()
  viewport!: Vector2

  @Prop()
  prevent!: boolean

  @Prop()
  landing!: boolean

  @Prop()
  scene!: string

  @Prop()
  mouse!: Vector2

  @Inject()
  camera!: PerspectiveCamera

  enabled = false

  collectionState = this.collection.map(() => ({
    focus: false,
  }))

  $el!: HTMLElement

  $refs!: {
    hotspots: HTMLElement[]
    panels: HTMLElement[]
    icons: HTMLElement[]
  }

  snapOffset = new Vector2()

  offset = new Vector2()

  threshold = 80

  get preventSnap () {
    return !!this.$device.mobile || this.prevent
  }

  select (hotspot: HotspotState) {
    console.log("点击热点", hotspot);
    this.setTrackEvent(hotspot)
    this.clear()
    if ('next-scene' !== hotspot.uid) {
      this.$emit('select', hotspot)
    } else {
      const  query = window.location.href;
      const vars = query.split("%");
      const varscene  = vars[1] || ""
      //切换没有参数
      if(!varscene){
        // wx.miniProgram.navigateTo({url: '/florafantasyworld/main/pages/login/login?payload='+"porch"})
        wx.miniProgram.navigateTo({url: '/pages/flora/index?payload='+ "porch"})
        return
      }
      this.$emit('next')
    }
  }

  setTrackEvent(hotspot: HotspotState){
      console.log("hotspot", hotspot);
      const url = hotspot.url || hotspot.uid
      const eventObject = [
        {name:'garden_bottle',type: Burieds.floraBottle},
        {name:'garden_flower',type: Burieds.pear},
        {name:'garden_jasmine',type: Burieds.jasmine},
        {name:'garden_gardenia',type: Burieds.gardenia},
        {name:"flora-videos", type: Burieds.hitCatPopVideo},
        {name:"flora-pattern", type:Burieds.passageHitWindow},
        {name: "ar-experience", type: Burieds.floraBottleHitArlens},
        {name: "the-soundtrack", type: Burieds.pinkGuitaMusicPop},
        {name: "the-purikura-experience", type: Burieds.pinkGuitaMusicThreePhoto},
        {name: "flora-stickers", type: Burieds.peachHitShell}
      ]
      const currentItem = eventObject.find(item => url ==item.name)
      if(currentItem){
        console.log("触发");
        const type = currentItem.type
        Request.setBuriedEvent(type).then( (res: any) => {
          console.log("res", res);
        })
      }
  }

  clear () {
    for (const state of this.collectionState) {
      state.focus = false
    }
  }

  tick () {
    const { hotspots, icons, panels } = this.$refs

    for (let i = 0; i < this.collection.length; ++i) {

      const hotspot = this.collection[i]

      const $hotspot = hotspots[i]

      gsap.set($hotspot, {
        x: hotspot.projection.x,
        y: hotspot.projection.y,
        force3D: true,
      })

      if (this.enabled && !this.preventSnap) {

        const $panel = panels[i]
        const $icon = icons[i]

        this.offset.x = hotspot.projection.x + this.viewport.x * .5
        this.offset.y = hotspot.projection.y + this.viewport.y * .5

        const distance = this.mouse.distanceTo(this.offset)

        this.collectionState[i].focus = distance < this.threshold

        this.snapOffset.set(0, 0)

        if (distance < this.threshold) {
          this.snapOffset.x = (this.mouse.x - this.offset.x) * .8
          this.snapOffset.y = (this.mouse.y - this.offset.y) * .8
        }

        gsap.to([$icon, $panel], {
          duration: 1, ease: 'power2.out',
          x: this.snapOffset.x,
          y: this.snapOffset.y,
        })
      }
    }
  }

  enter ($el: HTMLElement, done: () => void) {
    const $icons = $el.querySelectorAll('.icon')
    const $panels = $el.querySelectorAll('.panel')

    const delay = this.landing ? .6 : ENTER_DURATION

    gsap.timeline({ delay, onComplete: () => { this.enabled = true; done() } })
        .fromTo($icons, { scale: 0 }, { scale: 1, duration: 1.2, stagger: .12, ease: 'power2.inOut' }, '<')
        .fromTo($panels, { opacity: 0 }, { opacity: 1, duration: 1.2, stagger: .12, ease: 'power2.inOut' }, '<')
  }

  leave ($el: HTMLElement, done: () => void) {
    const $icons = $el.querySelectorAll('.icon')
    const $panels = $el.querySelectorAll('.panel')

    gsap.timeline({ onComplete: done })
        .to($icons, { scale: 0, duration: .6, ease: 'expo.out' }, '<')
        .to($panels, { opacity: 0, duration: .6, ease: 'expo.out' }, '<')
  }

  setup () {
    const { icons, panels } = this.$refs
    gsap.set(panels, { opacity: 0 })
    gsap.set(icons, { scale: 0 })
  }

  mounted () {
    this.$bus.$on(Events.GL.RENDER, this.tick)
    this.setup()
  }

  destroyed () {
    this.$bus.$off(Events.GL.RENDER, this.tick)
  }
}
