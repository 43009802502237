

























import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { gsap } from 'gsap'

@Component
export default class Footer extends Vue {
  @Getter('locale')
  locale!: boolean

  @Getter('native')
  native!: boolean

  get needsFooter () {
    return this.locale && !this.native
  }

  get cookieSettings () {
    return process.env.VUE_APP_COOKIE_SETTINGS_URL as string
  }

  @Watch('locale')
  localeUpdate (locale: boolean) {
    if (locale && this.$window.OneTrust) {
      this.setupCookies()
    }
  }
  
  /* showCookieSettings () {
    if (this.$window.OneTrust) {
      this.$window.OneTrust.ToggleInfoDisplay()
      //OneTrust.initializeCookiePolicyHtml()
    }
  } */

  setupCookies () {
    const privacyDocs = this.$t('footer.privacy.href') as string
    const cookiePolicyLinkNodes = document.querySelectorAll('a.custom-one-trust-cookie-policy-link:not([data-one-trust-cookie-policy-link-fixed])');
    [].forEach.call(cookiePolicyLinkNodes, (link: HTMLElement) => {     
      link.setAttribute('href', privacyDocs)
      link.setAttribute('target', '_blank')
      link.setAttribute('data-one-trust-cookie-policy-link-fixed', '')                 
    })
  }
  
  enter ($el: HTMLElement, done: () => void) {
    gsap.timeline({ delay: .5, onComplete: done })
        .fromTo($el, { yPercent: 120 }, { yPercent: 0, duration: 1, ease: 'power2.out' })
  }

  created () {
    this.$window.OptanonWrapper = () => {
      if (this.locale) {
        this.setupCookies()
      }
    }
  }
}
