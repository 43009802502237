import { Component, Vue } from 'vue-property-decorator'

import Burieds from "@/utils/buried";
import Request from "@/utils/request";

interface GTMEvent {
  eventCategory: string
  eventAction?: string
  eventLabel?: string
}

const dataLayer = (<any>window)['dataLayer']

export const GTMEventCategory = {
  ENVIRONMENT_MENU: 'Environment Quick Menu',
  START_EXPLORING: 'Clicks on "Start Exploring"',
  COOKIE_PRIVACY: 'Cookie & Privacy',
  TOGGL_AUDIO: 'Audio',
  SHOP_FLORA: 'Clicks on "Shop Flora"',
  PAGEVIEW: 'Scan QR Code',
  DOORS: 'Clicks on Doors',
}

export const GTMEventAction = {
  COOKIE: 'Clicks on Cookies',
  PRIVACY: 'Clicks on Privacy Policy',
  AUDIO_ON: 'on',
  AUDIO_OFF: 'off',
  SHOP: 'Clicks on "Shop Now"',
  BACK: 'Keep Exploring',
  LAUNCH_AR: 'Clicks on "Launch AR"',
  DOWNLOAD: 'Clicks on "Download"',
  PLAY: 'Clicks on "Play"',
  START_EXPERIENCE: 'Clicks on "Start the Experience"',
}



const setTrackEvent = (event: GTMEvent) => {
  const type = event.eventCategory;
  let label = ""
  if(type=="Soundtrack Hotpoint"){
    label = Burieds.pinkGuitaMusic
  }
  if(type== "Flora Videos Hotpoint"){
    const eventLabel = event.eventLabel;
      if(eventLabel == "The Campaign"){
        label =  Burieds.video1
      }
      if(eventLabel == "BTS"){
        label =  Burieds.video2
     }
  }
  Request.setBuriedEvent(label).then( (res: any) => {
    console.log("res", res);
  })
}

export const eventPush = (event: GTMEvent) => {
  dataLayer.push({ ...event, event: 'generic_event' })
  console.log("发eventPush",event);
  setTrackEvent(event)
}

@Component
export class TrackingProvider extends Vue {
  render () {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        GTMEventCategory,
        GTMEventAction,
        eventPush,
      })
    )
  }
}
