import { MutationTree } from 'vuex'
import { AppState, SET_COLLECTION, SET_COMPLETE, SET_FETCHED, SET_HOTSPOT, SET_LOCALE, SET_PROGRESS, SET_RETAILERS, SET_ROUTER, SET_SCENE, SET_SETTINGS, SET_STARTED, SET_TUTORIAL, SET_WELCOME } from './types'

export default <MutationTree<AppState>>{
  [SET_LOCALE] (state, payload) {
    state.locale = payload
  },

  [SET_ROUTER] (state, payload) {
    state.router = payload
  },

  [SET_FETCHED] (state, payload) {
    state.fetched = payload
  },

  [SET_PROGRESS] (state, payload) {
    state.progress = payload
  },

  [SET_COMPLETE] (state, payload) {
    state.complete = payload
  },

  [SET_WELCOME] (state, payload) {
    state.welcome = payload
  },

  [SET_TUTORIAL] (state, payload) {
    state.tutorial = payload
  },

  [SET_STARTED] (state, payload) {
    state.started = payload
  },

  [SET_STARTED] (state, payload) {
    state.started = payload
  },

  [SET_SETTINGS] (state, payload) {
    state.settings = { ...payload }
  },

  [SET_COLLECTION] (state, payload) {
    state.collection = payload
  },

  [SET_RETAILERS] (state, payload) {
    state.retailers = payload
  },

  [SET_HOTSPOT] (state, payload) {
    console.log("mutations里", payload);
    state.hotspot = payload
  },

  [SET_SCENE] (state, payload) {
    state.scene = payload
  }
}
