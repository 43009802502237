import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator'
import { AudioListener, Audio } from 'three'
import * as cache from '@/services/cache'
import gsap from 'gsap'

const SoundStore = {
  TRANSITION: 'sound-transition',
  HOTSPOT: 'sound-hotspot',
}

@Component
export class MixerProvider extends Vue {
  @Prop()
  playlist!: any

  @Prop()
  connect!: boolean

  listener = new AudioListener()

  store = {} as any

  muted = false
  
  @Watch('connect')
  async onConnect (connect: boolean) {
    if (!connect) return

    await this.$timer.defer()

    const sharedCache = cache.get('shared-cache')
    
    for (const track of this.playlist) {
      const { name, loop, volume, playbackRate } = track
      this.store[name] = new Audio(this.listener)
      this.store[name].setBuffer(sharedCache[name])
      this.store[name].setPlaybackRate(playbackRate)
      this.store[name].setVolume(volume)
      this.store[name].setLoop(loop)
      this.store[name].userData = { 
        playbackRate,
        volume,
        name,
      }
    }
  }

  @Provide()
  init (name: string | string[], detune = 0) {
    if (name instanceof Array) {
      for (const key of name)
        this.play(key, detune)
    } else this.play(name, detune)
    this.setVolume(0)
  }

  @Provide()
  play (name: string | string[], detune = 0) {
    const play = (name: string, detune: number) => {
      const sound = this.store[name]
      
      if (!sound) return

      const { userData: { volume } } = sound
      sound.detune = detune * 100
      sound.setVolume(0)

      if (sound.isPlaying)
        sound.stop()
        
      sound.currentTime = 0
      sound.play()
      gsap.to(
            { volume: 0 }
          , { volume, duration: 1, ease: 'none'
          , onUpdate: function () {
            if (sound.isPlaying) {
              const target = this.targets()[0]
              sound.setVolume(target.volume)
            }
          }
        })
    }

    if (name instanceof Array) {
      for (const key of name)
        play(key, detune)
    } else play(name, detune)
  }

  @Provide()
  stop (name: string) {
    const sound = this.store[name]
    
    if (!sound) return

    const { userData: { volume } } = sound
    
    gsap.to(
          { volume }
        , { volume: 0, duration: 1, ease: 'none'
        , onUpdate: function () {
          if (sound.isPlaying) {
            const target = this.targets()[0]
            sound.setVolume(target.volume)
          }
        }
        , onComplete: () => {
          if (sound.isPlaying)
            sound.stop() 
        }
      })
  }

  @Provide()
  setVolume (vol: number, fade = false) {
    for (const track of this.playlist) {
      const sound = this.store[track.name]
      const volume = track.volume * vol

      if (!sound) return
      
      if (!fade) {
        sound.setVolume(volume)
        sound.userData.volume = volume
      } else gsap.to({ volume: sound.userData.volume }
        , { volume, duration: .5, ease: 'none'
          , onUpdate: function () {
            const target = this.targets()[0]
            sound.setVolume(target.volume)
            sound.userData.volume = volume
          } 
        })
    }
  }

  toggle (muted: boolean) {
    const volume = !muted ? 1 : 0
    this.muted = muted
    this.setVolume(volume, true)
  }

  mute () {
    if (!this.connect) return
    this.setVolume(0)
  }
  
  unmute () {
    if (!this.connect) return
    this.setVolume(~~!this.muted)
  }
  
  render () {
    return (
      !this.$scopedSlots.$hasNormal &&
      this.$scopedSlots.default &&
      this.$scopedSlots.default({
        audioListener: this.listener,
        setVolume: this.setVolume,
        toggleSound: this.toggle,
        playSound: this.play,
        initSound: this.init,
        unmute: this.unmute,
        mute: this.mute,
        muted: this.muted,
        SoundStore,
      })
    )
  }
}