var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('StateProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var ref_settings = ref.settings;
  var sprinkles = ref_settings.sprinkles;
  var flowers = ref_settings.flowers;
  var lights = ref_settings.lights;
  var sounds = ref_settings.sounds;
  var bloom = ref_settings.bloom;
  var noise = ref_settings.noise;
  var glow = ref_settings.glow;
return [_c('TrackingProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var GTMEventCategory = ref.GTMEventCategory;
    var GTMEventAction = ref.GTMEventAction;
    var eventPush = ref.eventPush;
return [_c('ResizeProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var viewport = ref.viewport;
return [_c('PointerProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var normalized = ref.normalized;
        var spreaded = ref.spreaded;
        var coords = ref.coords;
        var delta = ref.delta;
return [_c('MixerProvider',{attrs:{"connect":_vm.complete,"playlist":sounds},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var audioListener = ref.audioListener;
          var toggleSound = ref.toggleSound;
          var SoundStore = ref.SoundStore;
          var initSound = ref.initSound;
          var playSound = ref.playSound;
          var unmute = ref.unmute;
          var muted = ref.muted;
          var mute = ref.mute;
return [_c('VisibilityProvider',{on:{"visible":unmute,"hidden":mute},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var visible = ref.visible;
return [_c('ThreeProvider',{attrs:{"audioListener":audioListener},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var pixelRatio = ref.pixelRatio;
              var snapTarget = ref.snapTarget;
              var syncDolly = ref.syncDolly;
              var snapDolly = ref.snapDolly;
              var bounding = ref.bounding;
              var camera = ref.camera;
              var scene = ref.scene;
return [_c('section',{class:{ devcam: _vm.devcam, debug: _vm.debug },attrs:{"id":"root"}},[_c('Canvas',{attrs:{"hotspots":_vm.hotspots,"viewport":viewport,"zoom":_vm.needsOverlay,"debug":_vm.devcam,"mouse":{
                    normalized: normalized,
                    spreaded: spreaded,
                    delta: delta,
                  }}},[_c('transition',{attrs:{"css":false,"appear":true,"mode":'out-in'},on:{"enter":_vm.sceneEnter,"leave":_vm.sceneLeave}},[(_vm.complete)?_c(_vm.sceneComponent,{key:(_vm.currentScene + "-scene"),ref:"scene",tag:"component",attrs:{"overlay":_vm.needsOverlay,"hotspots":_vm.hotspots,"bounding":bounding,"helpers":_vm.debug,"muted":muted}}):_vm._e()],1),_c('Starlight',{attrs:{"dpr":pixelRatio}}),(_vm.complete)?_c('Sprinkles',{attrs:{"hotspot":_vm.currentHotspot,"position":sprinkles.position,"reveal":sprinkles.reveal.value}}):_vm._e(),_c('Glow',{attrs:{"flare":glow.flare,"ratio":glow.ratio,"strength":glow.strength.value,"reveal":glow.reveal.value}}),(_vm.complete)?_c('Flowers',{attrs:{"translate":flowers.translate,"rotation":flowers.rotation,"scale":flowers.scale.value,"reveal":flowers.reveal.value}}):_vm._e(),_c('Lights',{attrs:{"directional":lights.directional,"ambient":lights.ambient,"helpers":_vm.debug}}),(_vm.needsEffects)?_c('Effects',[_c('RenderPass',{attrs:{"camera":camera,"scene":scene}}),_c('BloomPass',{attrs:{"threshold":bloom.threshold.value,"strength":bloom.strength.value,"radius":bloom.radius.value,"mask":bloom.mask.value,"enabled":bloom.enabled.value}}),_c('ChunkPass',[_c('RGBChunk',{attrs:{"angle":noise.angle.value,"amount":noise.amount.value,"enabled":noise.enabled.value}})],1)],1):_vm._e()],1),(_vm.needsLoader)?_c('Loader',{key:(_vm.currentScene + "-loader"),attrs:{"scene":_vm.currentScene}}):_vm._e(),_c('Dolly',{key:(_vm.currentScene + "-dolly"),attrs:{"bounding":bounding,"viewport":viewport,"snap":snapTarget},on:{"update":syncDolly}}),(_vm.active)?_c('Hotspots',{key:(_vm.currentScene + "-hotspots"),attrs:{"prevent":_vm.needsOverlay,"landing":_vm.sceneLanding,"collection":_vm.hotspots,"scene":_vm.currentScene,"viewport":viewport,"mouse":coords},on:{"next":function () {
                    eventPush({eventCategory: GTMEventCategory.DOORS, eventAction: _vm.$fn.capitalize(_vm.currentScene)})
                    playSound([SoundStore.HOTSPOT, SoundStore.TRANSITION])
                    _vm.gotoNextScene()
                  },"select":function (hotspot) {
                    _vm.setCurrentHotspot(hotspot)
                    snapDolly(hotspot.position.x)
                    playSound(SoundStore.HOTSPOT)
                  }}}):_vm._e(),(_vm.active)?_c('Navigator',{key:(_vm.currentScene + "-navigator"),attrs:{"landing":_vm.sceneLanding,"hotspots":_vm.hotspots},on:{"snap":snapDolly}}):_vm._e(),(_vm.started)?_c('Header',{attrs:{"muted":muted},on:{"scene":function (scene) {
                    _vm.setScene(scene)
                    playSound([SoundStore.HOTSPOT, SoundStore.TRANSITION])
                    eventPush({
                      eventCategory: GTMEventCategory.ENVIRONMENT_MENU,
                      eventAction: _vm.$fn.capitalize(scene),
                    })
                  },"shop":function (ref) {
                    var retailer = ref.retailer;

                    eventPush({
                      eventCategory: GTMEventCategory.SHOP_FLORA,
                      eventAction: retailer,
                    })
                  },"sound":function (muted) {
                    toggleSound(muted)
                    eventPush({
                      eventCategory: GTMEventCategory.TOGGL_AUDIO,
                      eventAction: muted
                        ? GTMEventAction.AUDIO_OFF
                        : GTMEventAction.AUDIO_ON
                    })
                  }}}):_vm._e(),(_vm.needsWelcome)?_c('Welcome',{on:{"elapsed":function($event){return _vm.setWelcomeState(true)}}}):_vm._e(),(_vm.needsTutorial)?_c('Tutorial',{on:{"confirm":function($event){return _vm.setTutorialState(true)}}}):_vm._e(),(_vm.needsLanding)?_c('Landing',{on:{"start":function () {
                    _vm.starExperience()
                    initSound([SoundStore.HOTSPOT, SoundStore.TRANSITION])
                    eventPush({eventCategory: GTMEventCategory.START_EXPLORING})
                  }}}):_vm._e(),(_vm.needsOverlay)?_c('Overlay',{attrs:{"muted":muted,"visible":visible,"viewport":viewport,"scene":_vm.currentScene,"hotspot":_vm.currentHotspot},on:{"pause":function($event){visible && !muted && unmute()},"play":function (video) {
                    visible && !muted && mute()
                    eventPush({
                      eventCategory: _vm.currentHotspot.gtm,
                      eventAction: GTMEventAction.PLAY,
                      eventLabel: video || '',
                    })
                  },"shop":function (ref) {
                    var retailer = ref.retailer;

                    eventPush({
                      eventCategory: _vm.currentHotspot.gtm,
                      eventAction: GTMEventAction.SHOP,
                      eventLabel: retailer,
                    })
                  },"ar":function (ref) {
                    var retailer = ref.retailer;

                    eventPush({
                      eventCategory: _vm.currentHotspot.gtm,
                      eventAction: GTMEventAction.LAUNCH_AR,
                      eventLabel: retailer,
                    })
                  },"exp":function (ref) {
                    var retailer = ref.retailer;

                    eventPush({
                      eventCategory: _vm.currentHotspot.gtm,
                      eventAction: GTMEventAction.START_EXPERIENCE,
                      eventLabel: retailer,
                    })
                  },"download":function () {
                    eventPush({
                      eventCategory: _vm.currentHotspot.gtm,
                      eventAction: GTMEventAction.DOWNLOAD,
                      eventLabel: _vm.currentRetailer.retailer,
                    })
                  },"back":function () {
                    eventPush({
                      eventCategory: _vm.currentHotspot.gtm,
                      eventAction: GTMEventAction.BACK,
                    })
                    _vm.setCurrentHotspot(null)
                  }}}):_vm._e()],1)]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }