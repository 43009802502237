import { getUTMParams } from '@/services/utm'

export const passiveSupport = (): boolean => {
  let support = false
  try {
    const opts = Object.defineProperty({}, 'passive', { get: () => support = true })
    window.addEventListener('test', () => { return }, opts)
  } catch (e) { console.log(e) }
  return support
}

export const traverse = (o: any, fn: (obj: any, prop: string, value: any) => void) => {
  for (const i in o) {
    fn.apply(this, [o, i, o[i]]);
    if (o[i] !== null && typeof(o[i]) === 'object') {
        traverse(o[i], fn);
    }
  }
}

export const enhancedIncludes = (source: string, match: string | string[]) => {
  if (!Array.isArray(match))
    return !!~source.indexOf(match)
  for (let i = match.length - 1; i >= 0; i--)
    if (~source.indexOf(match[i]))
      return true
  return false
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const passive = () => passiveSupport() ? { passive: false } : false

const _queries: any = {}
export const query = (key: string, value?: any) => {
  if (value && (_queries[key] = value),
  void 0 !== _queries[key])
    return _queries[key];
  // eslint-disable-next-line
  const str = decodeURI(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURI(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$','i'), '$1'));
  return '0' == str ? (_queries[key] = 0,
  0) : str.length && 'false' != str ? (_queries[key] = str,
  str) : (_queries[key] = location.search.includes(key),
  _queries[key])
}

export const parseURI = (url: string, native: boolean) => {
  const startChar = url.search(new RegExp('\\?', 'g')) > -1 ? '&' : '?'
  return `${url}${startChar}${getUTMParams(native)}` // encode ? `app://open-internal?url=${encodeURIComponent(url)}` : url
} 
  