






























import { Component } from 'vue-property-decorator'
import Heading from '@/components/atoms/Heading.vue'
import MediaWidget from './MediaWidget'

@Component({
  components: {
    Heading,
  }
})
export default class MobileLink extends MediaWidget {}
