Array.prototype.hasMin = function (attrib: string) {
  const checker = (o: any, i: string) => typeof(o) === 'object' && o[i]
  return (this.length && this.reduce(function(prev, curr){
      const prevOk = checker(prev, attrib);
      const currOk = checker(curr, attrib);
      if (!prevOk && !currOk) return {};
      if (!prevOk) return curr;
      if (!currOk) return prev;
      return prev[attrib] < curr[attrib] ? prev : curr; 
  })) || null;
}