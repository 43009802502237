





























import { gsap } from 'gsap'
import { LEAVE_DURATION } from '@/constants'
import { Component, Prop, Vue } from 'vue-property-decorator'
import SplitText from '@/components/atoms/SplitText.vue'
import Heading from '@/components/atoms/Heading.vue'

@Component({
  components: {
    Heading,
    SplitText,
  }
})
export default class Loader extends Vue {
  @Prop()
  scene!: string

  timeline!: gsap.core.Timeline

  $refs!: {
    split: SplitText
  }

  enter ($el: HTMLElement, done: () => void) {
    const $background = $el.querySelector('.loader__background')
    const $heading = $el.querySelector('.loader__heading h3 span')
    const $display = $el.querySelector('.loader__display p span')
    const $title = $el.querySelectorAll('.loader__title .char')

    if (this.timeline)
      this.timeline.kill()

    this.timeline = gsap.timeline({ delay: LEAVE_DURATION, onComplete: done })
        .fromTo($title
          , { xPercent: 100, opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` }
          , { xPercent: 0, opacity: 1, filter: `blur(${0}px)`, webkitFilter: `blur(${0}px)`
            , duration: .8, stagger: { amount: .8 }, ease: 'power2.out' }, '<')
        .fromTo($background, { opacity: 0 }, { opacity: 1, duration: 2, ease: 'power2.out' }, '<')
        .fromTo($heading, { yPercent: 120, opacity: 0 }, { yPercent: 0, opacity: .8, duration: 1, ease: 'power2.out' }, '<+.1')
        .fromTo($display, { yPercent: 120, opacity: 0  }, { yPercent: 0, opacity: .8, duration: 1, ease: 'power2.out' }, '<+.1')
  }

  leave ($el: HTMLElement, done: () => void) {
    const $background = $el.querySelector('.loader__background')
    const $heading = $el.querySelector('.loader__heading h3 span')
    const $display = $el.querySelector('.loader__display p span')
    const $title = $el.querySelectorAll('.loader__title .char')

    if (this.timeline)
      this.timeline.kill()

    this.timeline = gsap.timeline({ delay: 1, onComplete: done })
        .to($title
          , { xPercent: -100, opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)`
            , duration: .6, stagger: { amount: .4 }, ease: 'power2.inOut' }, '<')
        .to($background, { opacity: 0, duration: 2, ease: 'power2.inOut' }, '<')
        .to($heading, { yPercent: -120, opacity: 0, duration: .6, ease: 'power2.inOut' }, '<')
        .to($display, { yPercent: 120, opacity: 0, duration: .6, ease: 'power2.inOut' }, '<')
  }

  /* leave ($el: HTMLElement, done: () => void) {
    if (this.timeline)
      this.timeline.kill()
    
    this.timeline = gsap.timeline({ delay: .8, onComplete: done })
        .to($el, { opacity: 0, duration: 1, ease: 'power2.out' })
  } */
}
