import '@/setup'
import '@/polyfills'
import '@/vue/extends'
import '@/scss/main.scss'
//import '@/registerServiceWorker'
import { live } from '@/services/date'

import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import i18n from './i18n'

Vue.config.productionTip = false

if (live()) {
  console.log("axios");
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')

}
