































import { Component } from 'vue-property-decorator'
import Heading from '@/components/atoms/Heading.vue'
import Swiper, { Pagination } from 'swiper'
import MediaWidget from './MediaWidget'

Swiper.use([Pagination])


@Component({
  components: {
    Heading,
  }
})

export default class ImageGallery extends MediaWidget {
  swiper!: Swiper
  $refs!: {
    swiper: HTMLElement
    pagination: HTMLElement
  }
  
  // longClick!: number
  // timeOutEvent!: number

  //
  // start(){
  //   var that = this
  //   this.timeOutEvent = setTimeout(function(){
  //       that.longClick=1;
  //   },500)
  // },
  // end(){
  //   clearTimeout(this.timeOutEvent);
  //   if(this.timeOutEvent!=0 && this.longClick==0){//点击
  //        //此处为点击事件----在此处添加跳转详情页
  //       console.log('这是点击')
  //    }
  //    return false;
  // },

  mounted () {
    const { swiper, pagination } = this.$refs

    console.log("ImageGallery");

    this.swiper = new Swiper(swiper, {
      loop: true,
      spaceBetween: 160,
      pagination: {
        el: pagination,
        clickable: true,
      },
    })
  }

  async destroyed () {
    await this.$timer.defer(1)
    this.swiper.destroy(true, false)
  }
}
