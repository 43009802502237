
















































import { gsap } from 'gsap'
import { Getter } from 'vuex-class'
import { REVEAL_DURATION } from '@/constants'
import { RetailerState } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import i18n, { fetchI18nLocale, availableLanguages } from '@/i18n'
import SoundIcon from '@/components/atoms/SoundIcon.vue'
import Selector from '@/components/atoms/Selector.vue'
import LinkBtn from '@/components/atoms/LinkBtn.vue'
import Logo from '@/components/atoms/Logo.vue'
import wx from "weixin-js-sdk"

import Burieds from "@/utils/buried";
import Request from "@/utils/request";

@Component({
  components: {
    SoundIcon,
    Selector,
    LinkBtn,
    Logo,
  }
})
export default class Header extends Vue {
  @Getter('native')
  native!: boolean

  @Getter('scenes')
  scenes!: string[]

  @Getter('scene')
  currentScene!: string

  @Getter('shoplink')
  shoplink!: string

  @Getter('retailer')
  retailer!: RetailerState

  @Prop()
  muted!: boolean

  get currentLocale () {
    return i18n.locale
  }

  get languages () {
    const array = [...availableLanguages]
    array.pop()
    return array
  }

  get needsShopLink () {
    return this.shoplink !== undefined
  }

  async updateLocale (locale: string) {
    await fetchI18nLocale(locale)
  }

  //即刻选购
  shopNow(){
    let pathKey = "garden_now";
    if(this.currentScene == "garden"){
      pathKey = "garden_now"
      Request.setBuriedEvent(Burieds.gardonAddCart).then( (res: any) => {
        console.log("res", res);
      })
    }
    if(this.currentScene == "porch"){
      pathKey = "porch_now"
    }
    if(this.currentScene == "beach"){
      pathKey = "beach_now"
    }



    wx.miniProgram.navigateTo({url: `/florafantasyworld/main/pages/jump/jump?pathKey=${pathKey}`})
    // wx.miniProgram.navigateTo({url: '/photo-view/photo-view'})
    // wx.miniProgram.navigateTo({url: 'plugin://gucci-flora/home'})
  }

  enter ($el: HTMLElement, done: () => void) {
    const $brand = $el.querySelectorAll('.logo .logo__brand path')
    const $name = $el.querySelectorAll('.logo .logo__name path')
    const $copy = $el.querySelectorAll('.logo .logo__copy path')
    const $locale = $el.querySelector('.language-selector')
    const $sound = $el.querySelector('.sound-icon')
    const $link = $el.querySelector('.link-btn')

    gsap.timeline({ delay: REVEAL_DURATION, onComplete: done })
        .add(
          gsap.timeline()
              .fromTo([$el, $locale, $link, $sound], { opacity: 0 }, { opacity: 1, duration: .6, stagger: .12, ease: 'power2.inOut'  })
        , '<')
        .add(
          gsap.timeline()
              .fromTo($brand
                , { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` }
                , { opacity: 1, filter: `blur(${0}px)`, webkitFilter: `blur(${0}px)`
                  , duration: .6, stagger: { amount: .8 }, ease: 'power2.inOut' }
              , '<')
              .fromTo($name
                , { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` }
                , { opacity: 1, filter: `blur(${0}px)`, webkitFilter: `blur(${0}px)`
                  , duration: .6, stagger: { amount: .8 }, ease: 'power2.inOut' }
              , '<+.1')
              .fromTo($copy
                , { opacity: 0, filter: `blur(${5}px)`, webkitFilter: `blur(${5}px)` }
                , { opacity: 1, filter: `blur(${0}px)`, webkitFilter: `blur(${0}px)`
                  , duration: .6, stagger: { amount: .8 }, ease: 'power2.inOut' }
              , '<+.1')
        , '<')
  }
}
