









































import { Component, Prop, Vue, Watch} from 'vue-property-decorator'
import { availableLanguagesLabels } from '@/i18n'
import wx from "weixin-js-sdk"

@Component
export default class Selector extends Vue {
  @Prop()
  options!: string[]

  @Prop()
  current!: string

  @Prop()
  name!: string

  @Prop()
  dir!: string


  selected = ""


  @Watch('current')
  get(newVal: any, oldVal: any){
    this.selected = newVal
  }
  focus = false

  $refs!: {
    selection: HTMLElement
  }

  get label () {
    // garden "porch" "beach"
    const current = this.options.indexOf(this.current)
    return 'scenes' === this.name
      ? this.$t(`header.${this.name}[${current}]`)
      : 'languages' === this.name
        ? availableLanguagesLabels[current]
        : this.current
  }

  selectScene (selected: any) {
    const  query = window.location.href;
    const vars = query.split("%");
    const varscene  = vars[1] || ""
    if(!varscene){
      //wx.miniProgram.navigateTo({url: '/florafantasyworld/main/pages/login/login?payload='+selected})
      wx.miniProgram.navigateTo({url: '/pages/flora/index?payload='+ "porch"})
      this.selected = "garden"
      return
    }
    this.$emit('change', selected)
  }

  select () {
    this.focus = !this.focus
  }

  clear () {
    this.focus = false
  }


  mounted () {
    document.body.addEventListener('click', this.clear)
    this.selected = "garden"
  }

  destroyed () {
    document.body.removeEventListener('click', this.clear)
  }
}
