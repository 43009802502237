




















import { gsap } from 'gsap'
import { HotspotState } from '@/store/types'
import { ENTER_DURATION } from '@/constants'
import { PerspectiveCamera, Vector2 } from 'three'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Navigator extends Vue {
  @Prop()
  hotspots!: HotspotState[]

  @Prop()
  landing!: boolean

  @Inject()
  camera!: PerspectiveCamera

  vector = new Vector2()

  center = new Vector2()

  first = false

  last = false

  select (dir: number) {
    const distances = []

    const { length } = this.hotspots

    for (let i = 0; i < length; ++i) {
      const hotspot = this.hotspots[i]
      const distance = this.getDistanceFromCamera(hotspot)
      const distanceX = hotspot.position.x - this.camera.position.x - .001 * dir
      distanceX * dir > 0 && distances.push({ ...hotspot, distance, index: i })
    }

    const hotspot = distances.hasMin('distance') as any

    if (hotspot) {  
      //this.first = hotspot.index === 0
      //this.last = hotspot.index === length - 1

      this.$emit('snap', hotspot.position.x)
    }
  }

  enter ($el: HTMLElement, done: () => void) {
    const $arrows = $el.querySelectorAll('.navigator__arrow')

    const delay = this.landing ? .6 : ENTER_DURATION

    gsap.timeline({ delay, onComplete: done })
        .fromTo($arrows, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'power2.out' })
  }

  leave ($el: HTMLElement, done: () => void) {
    const $arrows = $el.querySelectorAll('.navigator__arrow')
    
    gsap.timeline({ onComplete: done })
        .to($arrows, { opacity: 0, duration: 1, ease: 'expo.inOut' })
  }

  getDistanceFromCamera (hotspot: HotspotState): number {
    const cameraPosition = this.camera.position.clone().setZ(0)
    const hotspotPosition = hotspot.position.clone().setZ(0)
    return cameraPosition.distanceTo(hotspotPosition)
  }
}
