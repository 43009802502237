













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DownloadIcon extends Vue {
  @Prop()
  source!: string

  @Prop()
  alias!: string
}
