import { AppState } from './types'
import { GetterTree } from 'vuex'
import { Vector2, Vector3 } from 'three'
import { parseURI } from '@/vue/modules/fn'

export default <GetterTree<AppState, any>>{
  scene: ({ scene }) => scene,
  locale: ({ locale }) => locale,
  scenes: ({ scenes }) => scenes,
  fetched: ({ fetched }) => fetched,
  started: ({ started }) => started,
  hotspot: ({ hotspot }) => hotspot,
  welcome: ({ welcome }) => welcome,
  progress: ({ progress }) => progress,
  complete: ({ complete }) => complete,
  tutorial: ({ tutorial }) => tutorial,
  settings: ({ settings }) => settings,
  dpr: ({ router: { to } }) => parseFloat(to.query.dpr),
  gui: ({ router: { to } }) => to.query.gui !== undefined,
  stats: ({ router: { to } }) => to.query.stats !== undefined,
  debug: ({ router: { to } }) => to.query.debug !== undefined,
  specs: ({ router: { to } }) => to.query.specs !== undefined,
  native: ({ router: { to } }) => to.query.lang !== undefined,
  devcam: ({ router: { to } }) => to.query.devcam !== undefined,
  retailer: ({ retailers, router: { to } }) => retailers[to.hash] || retailers['#default'],
  shoplink: (_, { native, retailer: { shop } }) => shop ? parseURI(shop, native) : undefined, // shop ? parseURI(shop, getUTMParams(shop, native)) : undefined,
  explink: (_, { native }) => parseURI(process.env.VUE_APP_EXP_URL as string, native), // parseURI(process.env.VUE_APP_EXP_URL as string, getUTMParams(shop, native)),
  arlink: (_, { native }) => parseURI(process.env.VUE_APP_AR_URL as string, native), // parseURI(process.env.VUE_APP_AR_URL as string, getUTMParams(shop, native)),
  bounding: ({ settings, scene }) => settings.bounding[scene] || { size: 0, depth: 0 },
  hotspots: ({ collection, scene }) => collection[scene]
    ? collection[scene].hotspots.map(({ uid, gtm, type, media, action, position, url })=> ({
        position: new Vector3().copy(position),
        projection: new Vector2(),
        uid, gtm, type, media, action,url
      }))
    : []
}
