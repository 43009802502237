export const WELCOME_DURATION = 3.5
export const REVEAL_DURATION = 3.5
export const LEAVE_DURATION = 3.8
export const ENTER_DURATION = 3

export const MediaQ = {
  XS: 360,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
}

export const Hash = {
  DEFAULT: '#default',
}

export const Scenes = {
  GARDEN: 'garden',
  PORCH: 'porch',
  BEACH: 'beach',
}

export const Events = {
  GL: {
    RENDER: 'gl:render',
    RESIZE: 'gl:resize',
    REVEAL: 'gl:reveal',
    ACTIVE: 'gl:active',
    COMPILE: 'gl:compile',
  },
  GUI: {
    CHANGE: 'gui:change',
  }
}

export const Widgets = {
  AR_QRCODE: 'ar-qrcode',
  AUDIO_PLAYER: 'audio-player',
  IMAGE_POSTER: 'image-poster',
  IMAGE_PATTERN: 'image-pattern',
  IMAGE_GALLERY: 'image-gallery',
  VIDEO_CAROUSEL: 'video-carousel',
}

export const WidgetActions = {
  NONE: 'none',
  SHOP: 'shop',
  EXP: 'exp',
  AR: 'ar',
}