








import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Landing extends Vue {
  @Prop()
  progress!: number

  get progressText () {
    return Math.round(this.progress * 100)
  }
}
