import Vue from 'vue'
import store from '@/store'
import Root from '@/views/Root.vue'
import Test from '@/views/Test.vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'root',
    component: Root
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const parseStoreRoute = ({ name, path, hash, query }: Route) => ({ name, path, hash, query })

router.beforeEach(async (to, from, next) => {
  await store.dispatch('syncRouter', {
    from: parseStoreRoute(from),
    to: parseStoreRoute(to),
  })

  if (!store.getters['locale']) {
    await store.dispatch('fetchLocale')
  }

  next()
})

export default router
