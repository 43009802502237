const storage = new Map()

export const add = (key: string, value: any) => {
  storage.set(key, value)
}

export const get = (key: string) => {
  return storage.get(key)
}

export const remove = (key: string) => {
  return storage.delete(key)
}

export const all = (callback: (value: any, key: any, map: Map<any, any>) => void) => {
  storage.forEach(callback)
}