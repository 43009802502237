import { settings } from '@/services/config'
import { AppState } from './types'
import { Scenes } from '@/constants'

export default <AppState>{
  collection: {},
  retailers: {},
  progress: 0,
  settings,
  complete: false,
  tutorial: false,
  welcome: false,
  fetched: false,
  started: false,
  hotspot: null,
  locale: false,
  scene: Scenes.GARDEN,  //fun?

  scenes: [
    Scenes.GARDEN,
    Scenes.PORCH,
    Scenes.BEACH,
  ],
  router: {
    to: {},
    from: {},
  }
}
