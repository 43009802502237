import { ActionTree } from 'vuex'
import { Hash, Scenes } from '@/constants'
import i18n, { fetchI18nLocale } from '@/i18n'
import { fetchWebFont } from '@/services/webfonts'
import { fetchSceneAssets } from '@/services/assets'
import { GTMEventCategory, eventPush } from '@/providers/modules/Tracking'
import { fetchRetailersData, fetchCollectionData } from '@/services/data'
import { AppState, SET_COLLECTION, SET_COMPLETE, SET_FETCHED, SET_HOTSPOT, SET_LOCALE, SET_PROGRESS, SET_RETAILERS, SET_ROUTER, SET_SCENE, SET_SETTINGS, SET_STARTED, SET_TUTORIAL, SET_WELCOME } from './types'
import wx from "weixin-js-sdk"

import Burieds from "@/utils/buried";
import Request from "@/utils/request";

export default <ActionTree<AppState, any>>{
  async fetchLocale ({ commit }) {
    await fetchI18nLocale()
    await fetchWebFont(i18n.locale)
    commit(SET_LOCALE, true)
  },

  async fetch ({ commit, dispatch }) {
    await fetchSceneAssets(Scenes.GARDEN)
    const { data: retailers } = await fetchRetailersData()
    const { data: collection } = await fetchCollectionData()
    commit(SET_COLLECTION, collection)
    commit(SET_RETAILERS, retailers)
    commit(SET_FETCHED, true)
    dispatch('gtmPush')
  },

  gtmPush({ getters }) {
    if (getters['retailer']) {
      eventPush({
        eventCategory: GTMEventCategory.PAGEVIEW,
        eventAction: getters['retailer'].retailer,
      })
    }
  },

  syncRouter ({ commit }, payload) {
    const { hash: _hash } = payload.to
    const hash = _hash.length ? _hash.split('?')[0] : Hash.DEFAULT
    commit(SET_ROUTER, {
      from: { ...payload.from },
      to: { ...payload.to, hash },
    })
  },

  gotoNextScene ({ commit, state: { scenes, scene } }) {
    const { length } = scenes
    const index = scenes.indexOf(scene)
    const next = index >= length - 1 ? 0 : index + 1
    console.log("点击门到下个场景", scenes[next]);
    const nextScene = scenes[next]
    const eventObject = [
      {name:'garden',type: Burieds.doorToGardon},
      {name: "beach", type: Burieds.doorToBeach},
      {name: "porch", type: Burieds.doorToPassage}
    ]
    const currentItem = eventObject.find(item => nextScene ==item.name)
    if(currentItem){
      const type = currentItem.type
      Request.setBuriedEvent(type).then( (res: any) => {
        console.log("res", res);
      })
    }
    commit(SET_SCENE, scenes[next])
  },

  setCurrentHotspot ({ commit }, payload) {
    console.log("store里AcTion", payload);

    commit(SET_HOTSPOT, payload)
  },

  setWelcomeState ({ commit }, payload) {
    commit(SET_WELCOME, payload)
  },

  setTutorialState ({ commit }, payload) {
    commit(SET_TUTORIAL, payload)
  },

  syncSettings ({ commit }, payload) {
    commit(SET_SETTINGS, payload)
  },

  setScene ({ commit }, payload) {
    console.log("切换场景", payload);
    const  query = window.location.href;
    const vars = query.split("%");
    const varscene  = vars[1] || ""
    //切换没有参数
    if(!varscene){
      console.log("跳转登录");
      // wx.miniProgram.navigateTo({url: '/florafantasyworld/main/pages/login/login?payload='+ payload})
      wx.miniProgram.navigateTo({url: '/pages/flora/index?payload='+ payload})
      return
    }

    const nextScene = payload
    const eventObject = [
      {name:'garden',type: Burieds.swithGardon},
      {name: "beach", type: Burieds.swithbeach},
      {name: "porch", type: Burieds.swithPassage}
    ]
    const currentItem = eventObject.find(item => nextScene ==item.name)
    if(currentItem){
      const type = currentItem.type
      Request.setBuriedEvent(type).then( (res: any) => {
        console.log("res", res);
      })
    }
    commit(SET_SCENE, payload)
  },

  progress ({ commit }, payload) {
    commit(SET_PROGRESS, payload)
  },

  finish ({ commit }) {
    commit(SET_COMPLETE, true)
  },

  start ({ commit }) {
    commit(SET_STARTED, true)
  },
}
