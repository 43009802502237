const Burieds = {

  pear: "pear", //点击查看鸭梨
  pearPop: "pearPop", //鸭梨弹窗点击跳转商城小程序

  floraBottle: "floraBottle", //花园场景点击查看Flora香水瓶
  floraBotterPop: "floraBotterPop", //花园Flora香水瓶弹窗点击跳转商城小程序次数

  jasmine: "jasmine", //点击查看茉莉花次数
  jasminePop: "jasminePop", //茉莉花弹窗点击跳转商城小程序次数

  gardenia: "gardenia", //点击查看白栀子花人数
  gardeniaPop: "gardeniaPop", //白栀子花弹窗点击跳转商城小程序人数

  hitCatPopVideo: "hitCatPopVideo", //门廊点击猫咪，弹出视频弹窗人数
  video1: "video1", //播放视频1次数
  video2: "video2", //播放视频2次数

  passageHitWindow: "passageHitWindow", //门廊点击窗户次数

  floraBottleHitArlens: "floraBottleHitArlens", //门廊点击Flora香水瓶
  hitAR: "hitAR", //点击开启AR增强现实体验次数


  pinkGuitaMusicPop: "pinkGuitaMusicPop", //沙滩点击粉色吉他，弹出音乐弹窗次数
  pinkGuitaMusic: "pinkGuitaMusic", //沙滩-点击吉他后音乐播放次数

  pinkGuitaMusicThreePhoto: "pinkGuitaMusicThreePhoto", //沙滩点击Flora香水瓶进入三连拍次数
  popSart: "popSart", //弹窗点击开始体验次数


  peachHitShell: "peachHitShell", //沙滩点击贝壳次数


  jumpingPageToShop: "jumpingPageToShop", //跳转商城小程序Jumping page点击进入线上旗舰店人数
  allowShop: "allowShop", //点击允许跳转商城小程序人数

  gardonAddCart: "gardonAddCart", //花园场景点击即刻选购次数

  doorToGardon: "doorToGardon", //点击门进入花园场景次数
  doorToBeach: "doorToBeach",//点击门进入沙滩场景次数
  doorToPassage: 'doorToPassage', //点击门进入门廊场景次数

  swithGardon: "swithGardon", //右上角切换进入花园场景人数
  swithbeach: "swithbeach", //右上角切换进入沙滩场景人数
  swithPassage: "swithPassage" //右上角切换进入门廊场景人数
}
export default Burieds
