
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SplitText as GsapSplitText } from 'gsap/SplitText'

@Component
export default class SplitText extends Vue {
  @Prop()
  type!: string // chars, words, lines

  split!: GsapSplitText

  get chars () {
    return this.split.chars
  }

  mounted () {
    this.split = new GsapSplitText(this.$el, { type: this.type, charsClass: 'char', linesClass: 'line' })
  }

  render() {
    return  this.$slots.default || []
  }
}
