
































import { Component } from 'vue-property-decorator'
import Heading from '@/components/atoms/Heading.vue'
import MediaWidget from './MediaWidget'

@Component({
  components: {
    Heading,
  }
})

export default class ImagePoster extends MediaWidget {
  mounted () {
    console.log("process.env", process.env);

    console.log("基础地址", process.env.BASE_URL);
  }
}
